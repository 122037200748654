
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import data from "./data";
import ConfirmationOrder from "./confirmation-order.vue";
import CardVerification from "./card-verification.vue";
import CardUpdate from "./card-update.vue";

export default defineComponent({
  name: "data-order",
  components: {
    Field,
    Loading,
    Multiselect,
    ConfirmationOrder,
    CardVerification,
    CardUpdate,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: data,
      listSchool: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      schoolId: "",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    // this.getData(this.paging.size, this.paging.page);
    // this.getListSekolah();
    setCurrentPageBreadcrumbs("Data Order", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      let search = "";
      let valschoolId = "";
      if (this.schoolId) {
        valschoolId = this.schoolId;
      }
      if (this.name) {
        search = this.name;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_order/product/all?name=" +
          search +
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=name&dir=1&productCatagory=" +
          valschoolId
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_order/product/combo_catagory").then(
        ({ data }) => {
          this.listSchool = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },

    pilihSekolah(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.schoolId = event;
      } else {
        this.schoolId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      ApiService.delete("crmv2/main_order/product/" + item._id)
        .then((res) => {
          toast.success("Hapus Berhasil");

          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
          this.isLoading = false;
        });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    changeModal() {},

    dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
